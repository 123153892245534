import React from 'react';
import Main from "./components/Main";
import NavBar from "./components/NavBar";


function App() {
  return (
    <div className="bg-[#0F1014] text-white ">
        <NavBar/>
     <Main/>

    </div>
  );
}

export default App;
